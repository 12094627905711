<template>
  <auth-bg>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4 mx-auto" v-if="!submitted">
          <div class="card py-4 px-3 rounded-5">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Sign up
            </div>
            <div class="card-body">

              <ValidationObserver v-slot="{ invalid }">
                <form class="auth-form" @submit.prevent="submit">
                  <ValidationProvider v-slot="v" rules="required|max:255" name="First Name">
                    <text-input v-model="form.first_name" label="First Name" placeholder="First Name" type="text"
                                :error="hasError('first_name', v.errors)"
                                :errors="getErrors('first_name', v.errors)"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-slot="v" rules="required|max:255">
                    <text-input v-model="form.last_name" label="Last Name" placeholder="Last Name" type="text"
                                :error="hasError('last_name', v.errors)"
                                :errors="getErrors('last_name', v.errors)"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-slot="v" rules="required|email" name="Email">
                    <text-input v-model="form.email" label="Email" placeholder="Email" type="email"
                                :error="hasError('email', v.errors)"
                                :errors="getErrors('email', v.errors)"
                    >

                      <template #append>
                        <span class="eye-btn"><i class="far fa-envelope"></i></span>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <ValidationProvider v-slot="v"
                                      rules="required|pass|min:6|max:32"
                                      name="Password">
                    <text-input v-model="form.password" label="Password" placeholder="Password"
                                :type="passwordOpen ? 'text': 'password'"
                                :error="hasError('password', v.errors)"
                                :errors="getErrors('password', v.errors)"
                    >
                      <template #append>
                        <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                          <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                        </button>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <ValidationProvider v-slot="v"
                                      :rules="{required: true, confirm: form.password,}"
                                      name="Password">
                    <text-input v-model="form.password_confirmation" label="Confirm Password"
                                placeholder="Confirm Password"
                                :error="hasError('password_confirmation', v.errors)"
                                :errors="getErrors('password_confirmation', v.errors)"
                                :type="passwordOpen ? 'text': 'password'"
                    >
                      <template #append>
                        <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                          <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                        </button>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase" type="submit"
                          :disabled="invalid">
                    Sign Up
                  </button>
                  <a @click.prevent="login" class="text-body text-center d-block mt-4 text-decoration-none" href="#">
                    Already have an account? <span class="text-primary text-decoration-underline">Log in</span></a>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div class="col-md-5 mx-auto" v-else>
          <div class="card py-5 px-3">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Please check your email
            </div>
            <div class="card-body text-center">
              <span>Your instructor profile has been submitted.</span><br>
              <span>We will approve your profile and be in contact shortly.</span><br>
              <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase mt-5"
                      @click.prevent="login">Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </auth-bg>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import AuthBg from "@/components/AuthBg";

export default {
  name: "Register",
  components: {AuthBg, TextInput},
  mixins: [hasApiValidation],
  data() {
    return {
      submitted: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      passwordOpen: false,
    }
  },
  methods: {
    submit() {
      this.setErrors({});
      this.$http.post('/auth/register', this.form)
          .then(() => {
            this.submitted = true;
            this.setErrors({});
          })
          .catch(({response}) => {
            this.$noty.error(response.data.message);
            this.setErrors(response.data.errors);
          });
    },
    forgot() {
      this.$router.push({name: 'ForgotPassword'});
    },
    login() {
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>

</style>