<template>
  <div class="">
    <div class="d-flex justify-content-between align-items-center mt-4 mb-2">
      <h1 class="fw-bold text-uppercase">Profile</h1>
      <button class="btn btn-primary rounded-pill py-2 text-uppercase" @click="save()">Save</button>
    </div>
    <template v-if="loaded">
      <div class="card mb-4 rounded-5 shadow">
        <div class="card-body px-lg-5 py-lg-4">
          <div class="d-flex align-items-center mb-3 mb-lg-5">
            <avatar-cropper
                v-model="trigger"
                @uploaded="handleUploaded"
                :uploadHandler="processFile"
                mimes="image/png, image/jpeg"
                :output-options="{ width: 400, height: 400 }"
            />
            <img :src="image" class="img-fluid rounded-circle me-4" style="width: 120px;height: 120px;object-fit: cover" alt="profile_img">
                <span class="cursor_pointer" @click="() => { trigger = true }">
              <i class="far fa-cloud-upload me-2 "></i> Upload new photo
            </span>
          </div>
          <ValidationObserver v-slot="{ handleSubmit}">
            <form @submit.prevent="handleSubmit(submit)" ref="form_profile">
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider v-slot="v" rules="required|max:255" name="First Name">
                    <text-input v-model="form.first_name" label="First Name" placeholder="First Name" required
                                :error="hasError('first_name', v.errors)"
                                :errors="getErrors('first_name', v.errors)"></text-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="v" rules="required|max:255">
                    <text-input v-model="form.last_name" label="Last Name" placeholder="Last Name" required
                                :error="hasError('last_name', v.errors)"
                                :errors="getErrors('last_name', v.errors)"></text-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="v" rules="phone">
                    <form-group :error="hasError('phone', v.errors)" :errors="getErrors('phone', v.errors)">
                      <template #label>
                        <label class="fw-medium"><span>Phone</span></label>
                      </template>
                      <VueTelInput v-model="form.phone" ref="phone_field"
                                   :class="{'is-invalid': hasError('phone', v.errors)}"/>
                    </form-group>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <text-input v-model="form.email" label="Email" placeholder="Email" required type="email"
                              readonly></text-input>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card shadow rounded-5">
        <div class="card-body px-lg-5 py-lg-4">
          <email-change :email_token="email_token" @change="emailChange"></email-change>
        </div>
      </div>
    </template>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import TextInput from "@/components/form/TextInput";
import FormGroup from "@/components/form/FormGroup";
import AvatarCropper from '@/components/form/vue-avatar-cropper'
import {VueTelInput} from 'vue-tel-input';
import {extend} from "vee-validate";
import EmailChange from "@/views/Profile/EmailChange";

extend('phone', () => {
  return 'Invalid phone number';
});

export default {
  name: "Profile",
  components: {EmailChange, FormGroup, TextInput, AvatarCropper, VueTelInput},
  mixins: [hasApiValidation],
  props: [
    'email_token',
  ],
  data() {
    return {
      trigger: false,
      image: null,
      loaded: false,
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        image_path: null,
      }
    }
  },
  mounted() {
    this.fetchData();
    extend('phone', this.validatePhone);
  },
  computed: {},
  methods: {
    emailChange() {
      this.fetchData();
      this.$auth.fetch({});
    },
    validatePhone(value) {
      if (value) {
        return this.$refs.phone_field.phoneObject.valid ? true : 'Invalid phone number';
      }
      return true;
    },
    save() {
      this.$refs.form_profile.dispatchEvent(new Event('submit'));
    },
    handleUploaded(event) {
      console.log(event)
    },
    processFile(event) {
      event.getCroppedCanvas({}).toBlob((blob) => {
        const extension = blob.type.split('/')[1];
        let fData = new FormData();
        fData.append('image', blob, 'filename.' + extension)
        this.setErrors({})
        this.$http.post('/profile/image', fData, {
          headers: {
            'Content-Type': "multipart/form-data"
          }
        }).then(({data}) => {
          this.image = data.image;
          this.form.image_path = data.image_path;
          this.$auth.fetch()
        }).catch(({response}) => {
          this.setErrors(response.data.errors)
          if (response.data.errors && response.data.errors.image) {
            response.data.errors.image.forEach((m) => {
              this.$noty.error(m);
            })
          }
        });
      });
    },


    // if (event.target.files.length === 0) {
    //   return;
    // }
    // let fData = new FormData();
    // fData.append('image', event.target.files[0])
    // this.setErrors({})
    // this.$http.post('/profile/image', fData).then(({data}) => {
    //   this.image = data.image;
    //   this.$auth.fetch()
    // }).catch(({response}) => {
    //   this.setErrors(response.data.errors)
    // });
    openUpload() {
      this.$refs.image_upload.click();
    },
    submit() {
      this.setErrors({})
      this.$http.post('/profile', this.form).then(() => {
        this.$noty.success('Saved');
        this.$auth.fetch()
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    },
    fetchData() {
      this.$http.get('/profile').then(({data}) => {
        this.form.first_name = data.data.first_name ?? '';
        this.form.last_name = data.data.last_name ?? '';
        this.form.email = data.data.email;
        this.form.phone = data.data.phone ?? '';
        this.form.image_path = data.data.image_path ?? null;
        this.image = data.data.image;
        this.loaded = true;
      })
    }
  }
}
</script>
<style scoped>
@import "https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.min.css";
</style>