var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"fw-bold text-uppercase my-4"},[_vm._v("Manage Classes")]),_c('div',{staticClass:"row align-items-center py-2"},[_c('div',{staticClass:"col-xl-6  d-flex align-items-center"},[_c('div',{staticClass:"dropdown me-3"},[_c('button',{staticClass:"btn btn-outline-primary dropdown-toggle rounded-pill",attrs:{"type":"button","id":"dropdownMenuButton1","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.statuses.find(function (item) { return item.value === _vm.filters.status; }).label)+" ")]),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},_vm._l((_vm.statuses),function(status){return _c('li',{key:status.value},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(status)}}},[_vm._v(_vm._s(status.label))])])}),0)]),_c('ul',{staticClass:"list-unstyled d-flex align-items-center mb-0 flex-wrap"},_vm._l((_vm.help_statuses),function(status){return _c('li',{key:status.value,staticClass:"me-3"},[_c('span',{staticClass:"status-badge me-2",class:{
                'bg-primary': status.value === 'released',
                'bg-secondary': status.value === 'draft',
                'bg-success': status.value === 'ready',
                'bg-warning': status.value === 'filmed',
              }}),_vm._v(" "+_vm._s(status.label)+" ")])}),0),_vm._m(0)]),_c('div',{staticClass:"col-xl-6 d-flex align-items-center flex-column flex-sm-row"},[_c('button',{staticClass:"btn btn-primary ms-sm-auto my-sm-0 my-2 text-uppercase rounded-pill py-2 col-12 col-sm-auto",on:{"click":function($event){return _vm.$router.push({name:'CreateClass'})}}},[_vm._v("Create New Class ")]),_c('text-input',{staticClass:"ms-2 col-12 col-sm-auto",attrs:{"placeholder":"Search","inline":""},on:{"change":_vm.fetchData},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"eye-btn"},[_c('i',{staticClass:"far fa-search"})])]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)]),_c('div',{staticClass:"card rounded mt-2 border-0 shadow"},[_c('main-table',{attrs:{"data":_vm.data,"columns":_vm.fields,"options":_vm.options,"loading":_vm.loading},on:{"update:sorter-value":_vm.sortingChange,"paginate":_vm.pageChange,"per_page_change":_vm.perPageChange},scopedSlots:_vm._u([{key:"name",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"data-bs-toggle":"tooltip","data-bs-placement":"top","title":item.status}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"status-badge me-1",class:{
              'bg-primary': item.status === 'released',
              'bg-secondary': item.status === 'draft',
              'bg-success': item.status === 'ready',
              'bg-warning': item.status === 'filmed',
            }})])]}},{key:"production_date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.production_date ? _vm.$moment(item.production_date).format('MM/DD/YYYY') : '')+" ")]}},{key:"production_time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.production_date ? _vm.$moment(item.production_date).format('HH:mm') : '')+" ")]}},{key:"release_date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.release_date ? _vm.$moment(item.release_date).format('MM/DD/YYYY') : '')+" ")]}},{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{staticClass:"text-uppercase me-2 text-decoration-none",attrs:{"to":{name:'ClassDetails', params:{id:item.id}},"title":"view"}},[_vm._v("View ")]),_c('router-link',{staticClass:"rounded-circle action_btn me-2 text-white bg-dark",attrs:{"to":{name:'EditClass', params:{id:item.id}},"title":"edit"}},[_c('i',{staticClass:"fal fa-edit"})]),_c('a',{staticClass:"rounded-circle action_btn me-2 text-white bg-dark",attrs:{"href":"#","title":"Clone"},on:{"click":function($event){$event.preventDefault();return _vm.copyClass(item)}}},[_c('i',{staticClass:"fal fa-copy"})])],1)]}}])})],1),_c('action-confirm-modal',{ref:"clone_modal",on:{"confirm":_vm.cloneClass}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"color-secondary hover:color-primary focus:color-primary",attrs:{"href":"#","data-bs-toggle":"popover","data-bs-placement":"bottom","data-bs-trigger":"hover focus","data-bs-content":"And here's some amazing content. It's very engaging. Right?"}},[_c('i',{staticClass:"fal fa-question-circle "})])}]

export { render, staticRenderFns }